footer {
    background-color: black;
    color: white;
    bottom: 0;
    width: 100vw;
    font-size: 16px;
  }
  footer * {
    box-sizing: border-box;
    border: none;
    outline: none;
  }

  .row {
    padding: 1em 1em;
  }
  .row.primary {
    display: grid;
    grid-template-columns: 3fr 2fr 4fr;
    align-items: stretch;
    background: black;
  }
  .column {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 2em;
    min-height: 15em;
  }
  h3 {
    width: 100%;
    text-align: left;
    color: white;
    font-size: 1.4em;
    white-space: nowrap;
  }
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
  }
  li:not(:first-child) {
    margin-top: 0.8em;
  }
  ul li a {
    color: #a7a7a7;
    text-decoration: none;
  }
  ul li a:hover {
    color: #c7940a;
  }
  .about {
    color: #fff;
    p {
        // text-align: justify;
        line-height: 24px;
        margin: 0;
        font-size: 18px;
        font-weight: 700;
    }
    h6 {
        font-size: 16px;
        margin-top: 10px;
        line-height: 22px;
    }
  }
  input,
  button {
    font-size: 1em;
    padding: 1em;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 5px;
  }
  button {
    background-color: #c7940a;
    color: #ffffff;
  }
  div.social {
    display: flex;
    justify-content: space-around;
    font-size: 2.4em;
    flex-direction: row;
    margin-top: 0.5em;
  }
  .social i {
    color: #bac6d9;
  }
  
  .copyright {
    padding: 0.3em 1em;
    background-color: #25262e;
  }
  .footer-menu{
    float: left;
      margin-top: 10px;
  }
  .footer-menu a{
    color: #cfd2d6;
    padding: 6px;
    text-decoration: none;
  }
  .footer-menu a:hover, .social i:hover{
    color: #c7940a;
  }
  .copyright p {
    font-size: 0.9em;
    text-align: right;
    color: #fff;
  }