.App-header {
    background: black;
    padding: 5px;
    h1 {
        color: #fff;
        font-size: 15px;
        @media only screen and (min-width: 768px) {
            font-size: 30px;
          }
    }
    a {
        cursor: pointer;
        color: #fff;
        text-decoration: none;
    }
}

.App {
    svg {
        height: 41px;
        margin-top: 6px;
        margin-left: 11px;
        fill: white;
        @media (max-width: 767px) {
            height: 33px; 
        }
    }
}