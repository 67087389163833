@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;1,300&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

// @import url("./fix-slick.scss");
@import url("~slick-carousel/slick/slick.css");
@import url("~slick-carousel/slick/slick-theme.css");



body {
  font-family: 'Roboto', sans-serif;
  margin: 0 auto;
}

.App-header {
  background: black;
  padding: 5px;
  h1 {
      color: #fff;
      font-size: 15px;
      @media only screen and (min-width: 768px) {
          font-size: 30px;
        }
  }
  a {
      cursor: pointer;
      color: #fff;
      text-decoration: none;
  }
}
